import { render, staticRenderFns } from "./ProductWithTiers.vue?vue&type=template&id=076cf733&scoped=true"
import script from "./ProductWithTiers.vue?vue&type=script&lang=js"
export * from "./ProductWithTiers.vue?vue&type=script&lang=js"
import style0 from "./ProductWithTiers.vue?vue&type=style&index=0&id=076cf733&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "076cf733",
  null
  
)

export default component.exports