<template>
  <v-row>
    <v-col
      v-if="titleShow || add"
      cols="12"
      sm="12"
    >
      <div class="d-flex justify-space-between align-center">
        <span
          v-show="titleShow"
          class="text-h3"
        >{{ $t(title) }}</span>
        <div
          v-if="add && !(items.length == productWithTiers.length)"
          class="d-flex justify-end"
        >
          <v-btn
            small
            color="primary"
            class="mx-0"
            @click="addRow"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
    <template v-for="(item, i) in items">
      <v-col
        :key="i"
        cols="12"
        sm="6"
      >
        <v-select
          v-model="item.product_id"
          dense
          clearable
          :disabled="i == 0"
          :items="productWithTiers"
          :item-disabled="checkPrevious"
          item-value="id"
          item-text="name"
          :label="$t('product_*')"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col
        :key="`tier${i}`"
        cols="12"
        :sm="i == 0 ? '6' : '5'"
      >
        <!-- :sm="i == 0 ? '6' : '5'" -->
        <v-select
          v-model="item.product_tier_id"
          dense
          :items="filterProductTier(item.product_id)"
          item-value="product_tier_id"
          item-text="name"
          :label="$t('product_tiers_*')"
          :rules="[rules.required]"
          @change="onTiersChange"
        />
      </v-col>
      <!-- v-if="i != 0" -->

      <v-col
        v-if="i != 0"
        :key="`action${i}`"
        cols="1"
        class="d-flex align-center"
      >
        <!-- <div v-if="i == 0" /> -->
        <!-- v-else -->
        <div
          class="action-btn"
          @click="deleteRow(i)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  props: {
    itemList: {
      type: Array,
      default() {
        return [
          {
            product_id: null,
            product_tier_id: null,
          },
        ];
      },
    },
    titleShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    title: {
      type: String,
      default() {
        return "product_tiers";
      },
    },
    add: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      items: [...this.itemList],
    };
  },
  computed: {
    ...mapGetters({
      productWithTiers: "products/getProductWithTiers",
    }),
  },
  async beforeMount() {
    if (this.itemList[0].id) {
      this.onTiersChange();
    }
    await this.$store.dispatch("products/product_with_tiers");
  },
  methods: {
    addRow() {
      if (this.items.length < this.productWithTiers.length) {
        this.items.push({
          product_id: null,
          product_tier_id: null,
        });
      }
    },
    checkPrevious(val) {
      const proID = this.items.map((r) => r.product_id);
      return proID.includes(val.id);
    },
    filterProductTier(val) {
      if (val) {
        return this.productWithTiers.find((r) => r.id == val)?.tiers;
      } else {
        return [];
      }
    },
    deleteRow(v) {
      this.items = this.items.filter((r, i) => i != v);
      this.onTiersChange();
    },
    onTiersChange() {
      this.$emit(
        "change",
        this.items.map((r) => r.product_tier_id)
      );
    },
  },
};
</script>
<style lang="sass" scoped>
.action-btn
    cursor: pointer
    &:hover
        i
            color: #20a390
</style>
